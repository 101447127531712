import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContentContainer } from "../components/Theme"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ParallaxBackground from "../components/ParallaxBackground"
import { renderOption } from "../utils/contentful-options"

export default function about({ data }) {
  const title = data.allContentfulAboutPage.edges[0].node.title
  const content = data.allContentfulAboutPage.edges[0].node.content.json
  const heroImage = data.allContentfulAboutPage.edges[0].node.heroImage.file.url

  return (
    <Layout>
      <SEO title="About me" />

      <ParallaxBackground
        bgImage={heroImage}
        bgImageAlt="About page"
        height="50vh"
        strength={"75"}
      >
        <h1 data-sal="fade" data-sal-easing="ease">
          {title}
        </h1>
      </ParallaxBackground>

      <ContentContainer>
        <section style={{ marginTop: "30px" }}>
          {documentToReactComponents(content, renderOption)}
        </section>
      </ContentContainer>
    </Layout>
  )
}

export const aboutPageQuery = graphql`
  query {
    allContentfulAboutPage {
      edges {
        node {
          title
          content {
            json
          }
          heroImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`
